<template lang="pug">
    section#formulario
        .wrapper
            .text
                h3 {{ formTitle }}
                h4.sub-title(v-if="subTitle") {{ subTitle }}
                p
                    |Saiba como funcionamos e que tipo de soluções podemos oferecer para o seu negócio.

                .person
                    h4 Thais Scaglione
                    p.cargo Manager of Strategic Desing & Innovation
                    a(href="tel:+554135255680") +55 41&nbsp
                        strong 3525 5717
                .person
                    h4 Isabela Pacheco
                    p.cargo Head of Business Process & Innovation
                    a(href="tel:+554135255680") +55 41&nbsp
                        strong 3525 5680

            .box
                SvgIcon(data="@svgs/equipe/explosion.svg", original).explosion
                form(@submit.prevent="send")
                    h4 Qual seu desafio hoje?
                    p Preencha o formulário e vamos conversar
                    .input(:class="{ error: form_error.nome }")
                        label(for="nome") Nome*
                        input(type="text" name="nome" v-model="form.nome")
                        span {{ form_error.nome }}
                    .input(:class="{ error: form_error.email }")
                        label(for="email") Email*
                        input(type="email" name="email" v-model="form.email")
                        span {{ form_error.email }}
                    .input(:class="{ error: form_error.telefone }")
                        label(for="telefone") Telefone*
                        input(type="tel" name="telefone" v-model="form.telefone" v-mask="['(##) #####-####', '(##) ####-####']")
                        span {{ form_error.telefone }}
                    .input(:class="{ error: form_error.estado }")
                        label(for="estado") Estado*
                        input(type="text" name="estado" v-model="form.estado")
                        span {{ form_error.estado }}
                    .input(:class="{ error: form_error.empresa }")
                        label(for="empresa") Empresa*
                        input(type="text" name="empresa" v-model="form.empresa")
                        span {{ form_error.empresa }}
                    .input(:class="{ error: form_error.mensagem }")
                        label(for="mensagem") Mensagem*
                        textarea(name="mensagem" v-model="form.mensagem" rows="10")
                        span {{ form_error.mensagem }}
                    .checkbox
                        input(type="checkbox" v-model="agreed")
                        span Estou ciente de que forneço meus dados pessoais aos canais de comunicação da Mindservice, com a finalidade de buscar soluções para meu negócio e clientes. Tais dados serão tratados nos termos da Política de Privacidade da Mindservice e da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”).
                    button(type="submit", v-html="buttonText", :disabled="disabled || !this.agreed")
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
    name: "component-formulario",
    directives: {
        mask
    },
    props: {
        formTitle: String,
        subTitle: String,
    },
    data() {
        return {
            form: {
                nome: '',
                email: '',
                telefone: '',
                estado: '',
                empresa: '',
                mensagem: '',
            },
            agreed: false,
            form_error: {},
            buttonText: "Enviar",
            disabled: false,
        }
    },
    methods: {
        send() {
            if(this.agreed)
                this.buttonText = "Enviando..."
                this.disabled = true
                this.form_error = {}
                this.$axios
                    .post(`send_mail.php`, this.form)
                    .then(response => {
                        this.buttonText = "Enviado com sucesso!"
                        this.form = {
                            nome: '',
                            email: '',
                            telefone: '',
                            estado: '',
                            empresa: '',
                            mensagem: '',
                        }
                    })
                    .catch(error => {
                        this.form_error = error.response.data.errors
                        this.buttonText = "Ocorreu um erro!"
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.disabled = false
                            this.buttonText = "Enviar"
                        }, 5000);
                    })
        },
    }
}
</script>

<style lang="stylus" scoped src="./Formulario.styl"></style>
